<template>
  <div>
    <v-form>
      <p class="mb-3">
        Examine the
        <chemical-latex content="^1H" />
        spectrum of compound C.
      </p>
      <p class="mb-3">Given is a table summarizing the spectral data.</p>
      <v-simple-table class="mb-3">
        <tr>
          <th></th>
          <th style="text-align: left">ppm</th>
          <th style="text-align: left">Integration</th>
          <th style="text-align: left">Splitting</th>
        </tr>
        <tr v-for="option in tableOptions" :key="option">
          <td v-for="part in option" :key="part">{{ part }}</td>
        </tr>
      </v-simple-table>
      <p class="mb-3">
        Which two signals are consistent with the presence of an <i>iso</i>-propyl group?
      </p>
      <p v-for="option in options" :key="option" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.multiSelectAnswers" :value="option" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option" />
          </template>
        </v-checkbox>
      </p>

      <p class="nb-n3 mt-3">What alkyl fragments are the other two signals consistent with?</p>
      <v-radio-group v-model="inputs.checkboxAnswer" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in checkboxOptionsShuffled"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI51LBA2_M4Q3c',
  components: {ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        multiSelectAnswers: [],
        checkboxAnswer: null,
      },
      tableOptions: [
        ['A', '4.10', '2 H', 'quartet'],
        ['B', '2.65', '1 H', 'multiplet'],
        ['C', '1.30', '3 H', 'triplet'],
        ['D', '1.20', '6 H', 'doublet'],
      ],
      options: ['A', 'B', 'C', 'D'],
      checkboxOptions: [
        {
          text: 'ethyl group',
          value: 'ethyl',
        },
        {
          text: '$n$-propyl group',
          value: 'nPropyl',
        },
        {
          text: '$iso$-propyl group',
          value: 'isoPropyl',
        },
        {
          text: '$n$-butyl group',
          value: 'nButyl',
        },
        {
          text: '$tert$-butyl group',
          value: 'tertButyl',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    checkboxOptionsShuffled() {
      return seededShuffle(this.checkboxOptions, this.seed);
    },
  },
};
</script>
